
/* Special styling for special browser */


.the-slideshow .item { position:relative; }


/* Header
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

/* Quick search
-------------------------------------------------------------- */
.form-search .input-text::-ms-clear { display:none; }


/* Remember Me Popup
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/
.window-overlay { background-color:#000; filter:alpha(opacity=35); }


/* Product page
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/
.product-view .add-to-cart label { margin-left: 10px; }



/* From Magento default styles-ie.css %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */

/* Clears and hasLayout fixes */
.header-container,
.header-container .top-container,
.header,
.header .quick-access,
#nav,
.main,
.footer,
.footer-container .bottom-container,
.col-main,
.col2-set,
.col3-set,
.col3-layout .product-options-bottom .price-box,
.col4-set,
.messages li,
.search-autocomplete li,
.block,
.block .block-content,
.block .actions,
.block li.item,
.block-poll li,
.block-poll .label,
.block-layered-nav .currently li,
.block-account .block-content li a,
.mini-products-list .product-details,
.page-title,
.rss-title h1,
.products-grid,
.products-list li.item,
.box-account .box-head,
.dashboard .box .box-title,
.box-reviews li.item,
.box-tags li.item,
.pager,
.sorter,
.ratings,
.add-to-box,
.add-to-cart,
.product-essential,
.product-collateral,
.product-view .product-img-box .more-views ul,
.product-view .box-tags .form-add,
.product-view .product-shop .short-description,
.product-view .box-description,
.product-options .options-list li,
.product-options,
.product-options-bottom,
.truncated,
.truncated .truncated_full_value,
.product-review,
.cart,
.cart-collaterals,
.cart .crosssell li.item,
.cart .discount,
.opc .step-title,
.opc .step,
.multiple-checkout,
.sp-methods,
.checkout-progress,
.multiple-checkout .place-order,
.form-list li,
.form-list .field,
.group-select li,
.buttons-set,
.page-print .print-head,
.cart-tax-total,
.advanced-search-summary,
.advanced-search-summary p,
.gift-messages-form .item,
.send-friend .form-list li p,
.centinel .authentication { zoom:1; }

/* Hover Fix */
iframe.hover-fix { position:absolute; left:-1px; top:-1px; z-index:-1; background:transparent;
	filter:progid:DXImageTransform.Microsoft.Alpha(style=0,opacity=0);
}

